import Cookie from 'js-cookie';

import { getCookieConsent } from './cookies';


// Delete GA cookies

function gaDeleteCookies() {
  Cookie.remove('_ga');
  Cookie.remove('_gat');
  Cookie.remove('_gid');

  const allCookies = Cookie.get();
  const cookieName = Object.keys(allCookies).find((key) => key.startsWith('_ga_'));

  if (cookieName) {
    Cookie.remove(cookieName);
  }
}


// Handle GA Consent

export function gaHandleConsent() {
  const { analytics, marketing } = getCookieConsent();
  const disable = !(analytics && marketing);


  if (window.gtag) {
    if (disable) {
      gaDeleteCookies();
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
      });
    }


    window.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  }
}

// Initialize GA

export function gaInit() {
  gaHandleConsent();
}

// Exception Tracking

export function trackException(description, fatal) {
  if (window.gtag) {
    window.gtag('event', 'exception', {
      description,
      fatal,
    });
  }
}


export function trackReservationPurchase(addOns, reservation) {
  let total = 0;

  // Room Item

  const roomItem = {
    item_id: reservation.confirmationId,
    item_name: reservation.stay.roomTypeName,
    item_category: 'Room',
    item_variant: `${reservation.stay.roomType}:${reservation.stay.ratePlan}`,
    price: reservation.stay.total / reservation.stay.duration,
    quantity: reservation.stay.duration,
  };

  total += reservation.stay.total;

  // AddOn Items

  const addOnItems = addOns.map((a) => {
    total += a.total;

    return {
      item_id: reservation.confirmationId,
      item_name: a.name,
      item_category: 'Add On',
      item_variant: a.code,
      price: a.total / a.quantity,
      quantity: a.quantity,
    };
  });

  // Combine all items

  const items = [roomItem, ...addOnItems];

  // Transaction Object

  const transaction = {
    transaction_id: reservation.confirmationId,
    value: total,
    currency: 'EUR',
    items,
  };


  window.gtag('event', 'purchase', transaction);
}
